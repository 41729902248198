import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';

const SocialMediaOptimizationSimulatorSection = ({ data, plan, service }) => {
  
  const {t} = useTranslation();
  const {language} = useI18next();

  // Social Medias
  const [socialMedias, setSocialMedias] = useState(['Google']);
  const [socialMediasSelected, setSocialMediasSelected] = useState([{ value: 'google', label: 'Google' }]);

  // Social Medias Options
  const optionsSocialMedias = [
    { value: 'google', label: 'Google' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'tiktok', label: 'TikTok' },
    { value: 'pinterest', label: 'Pinterest' },
    { value: 'pagesjaunes', label: 'PagesJaunes' },
    { value: 'tripadvisor', label: 'Tripadvisor' },
    { value: 'thefork', label: 'TheFork' }
  ]

  // Handle Change Social Medias
  const handleChangeSocialMedias = (events) => {
    var updatedList = [];
    var updatedListSelected = [];
    events.forEach(({label,value}) => {
      updatedList = [...updatedList, label];
      updatedListSelected = [...updatedListSelected, { value: value, label: label }];
    });
    setSocialMedias(updatedList);
    setSocialMediasSelected(updatedListSelected);
  };

  // Photos
  const [photosSelected, setPhotosSelected] = useState({ label: plan.custom.photos.included, value: plan.custom.photos.included });

  // Photos Options
  var optionsPhotosList = [];
  for (var i1 = plan.custom.photos.number_minimum; i1 <= plan.custom.photos.number_maximum; i1++) {
    optionsPhotosList.push({ label: i1, value: i1 })
  }
  const [optionsPhotos] = useState(optionsPhotosList);

  // Handle Change Photos
  const handleChangePhotos = (obj) => {
    setPhotosSelected(obj);
  };

  // Services
  const [servicesSelected, setServicesSelected] = useState({ label: plan.custom.services.included, value: plan.custom.services.included });

  // Services Options
  var optionsServicesList = [];
  for (var i2 = plan.custom.services.number_minimum; i2 <= plan.custom.services.number_maximum; i2++) {
    optionsServicesList.push({ label: i2, value: i2 })
  }
  const [optionsServices] = useState(optionsServicesList);

  // Handle Change Services
  const handleChangeServices = (obj) => {
    setServicesSelected(obj);
  };

  // Products
  const [productsSelected, setProductsSelected] = useState({ label: plan.custom.products.included, value: plan.custom.products.included });

  // Products Options
  var optionsProductsList = [];
  for (var i3 = plan.custom.products.number_minimum; i3 <= plan.custom.products.number_maximum; i3++) {
    optionsProductsList.push({ label: i3, value: i3 })
  }
  const [optionsProducts] = useState(optionsProductsList);

  // Handle Change Products
  const handleChangeProducts = (obj) => {
    setProductsSelected(obj);
  };

  // Languages
  const [languagesSelected, setLanguagesSelected] = useState({ label: plan.custom.languages.included, value: plan.custom.languages.included });

  // Languages Options
  var optionsLanguagesList = [];
  for (var i4 = plan.custom.languages.number_minimum; i4 <= plan.custom.languages.number_maximum; i4++) {
    optionsLanguagesList.push({ label: i4, value: i4 })
  }
  const [optionsLanguages] = useState(optionsLanguagesList);

  // Handle Change Languages
  const handleChangeLanguages = (obj) => {
    setLanguagesSelected(obj);
  };

  // Shootings
  const [shootingsSelected, setShootingsSelected] = useState({ label: plan.custom.shootings.included, value: plan.custom.shootings.included });

  // Shootings Options
  var optionsShootingsList = [];
  for (var i5 = plan.custom.shootings.number_minimum; i5 <= plan.custom.shootings.number_maximum; i5++) {
    optionsShootingsList.push({ label: i5, value: i5 })
  }
  const [optionsShootings] = useState(optionsShootingsList);

  // Handle Change Shootings
  const handleChangeShootings = (obj) => {
    setShootingsSelected(obj);
  };

  // Simulator
  const [price, setPrice] = useState(null);
  const [delivered, setDelivered] = useState(null);
  const [modifications, setModifications] = useState(null);

  const [socialMediaPhotos, setSocialMediaPhotos] = useState(0);
  const [socialMediaServices, setSocialMediaServices] = useState(0);
  const [socialMediaProducts, setSocialMediaProducts] = useState(0);

  // Update Simulator
  const updateSimulator = value => {

    // Plan
    var price = plan.price;
  
    // Social Medias
    if(plan.custom.social_medias.active === true) {
      var socialMediaPhotosActive = 0;
      var socialMediaServicesActive = 0;
      var socialMediaProductsActive = 0;
      socialMediasSelected.forEach(social_media => {
        if(plan.custom.social_medias[social_media.value]){
          if(plan.custom.social_medias[social_media.value].photo_active === true){
            socialMediaPhotosActive++;
          }
          if(plan.custom.social_medias[social_media.value].service_active === true){
            socialMediaServicesActive++;
          }
          if(plan.custom.social_medias[social_media.value].product_active === true){
            socialMediaProductsActive++;
          }
        }
      });
      setSocialMediaPhotos(socialMediaPhotosActive);
      setSocialMediaServices(socialMediaServicesActive);
      setSocialMediaProducts(socialMediaProductsActive);
    }

    // Social Medias
    if(plan.custom.social_medias.active === true && socialMediasSelected.length > 0) {
      price += plan.custom.social_medias.active_price;
      if(socialMediasSelected.length > 1){
        price += plan.custom.social_medias.additional_price * ( socialMediasSelected.length - 1 );
      }
    }

    // Photos
    if(plan.custom.photos.active === true && photosSelected.value > 0 && socialMediaPhotosActive > 0) {
      // Creation
      if(plan.custom.photos.included === 0){
        price += plan.custom.photos.active_price;
        if(photosSelected.value > 1){
          price += plan.custom.photos.additional_price * ( photosSelected.value - 1 );
        }
      }else{
        if(photosSelected.value > plan.custom.photos.included) {
          price += plan.custom.photos.active_price;
          if(photosSelected.value > plan.custom.photos.included){
            price += plan.custom.photos.additional_price * ( photosSelected.value - plan.custom.photos.included );
          }
        }
      }
      // Publish
      if(plan.custom.photos.included === 0){
        price += plan.custom.photos.publish_active_price;
        if(socialMediaPhotosActive > 1){
          price += plan.custom.photos.publish_additional_price * photosSelected.value * ( socialMediaPhotosActive - 1 );
        }
      }else{
        if(photosSelected.value > plan.custom.photos.included) {
          price += plan.custom.photos.publish_active_price;
          if(socialMediaPhotosActive > 1){
            price += plan.custom.photos.publish_additional_price * ( photosSelected.value - plan.custom.photos.included ) * ( socialMediaPhotosActive - 1 );
          }
        }
      }
    }

    // Services
    if(plan.custom.services.active === true && servicesSelected.value > 0 && socialMediaServicesActive > 0) {
      // Creation
      if(plan.custom.services.included === 0){
        price += plan.custom.services.active_price;
        if(servicesSelected.value > 1){
          price += plan.custom.services.additional_price * ( servicesSelected.value - 1 );
        }
      }else{
        if(servicesSelected.value > plan.custom.services.included) {
          price += plan.custom.services.active_price;
          if(servicesSelected.value > plan.custom.services.included){
            price += plan.custom.services.additional_price * ( servicesSelected.value - plan.custom.services.included );
          }
        }
      }
      // Publish
      if(plan.custom.services.included === 0){
        price += plan.custom.services.publish_active_price;
        if(socialMediaServicesActive > 1){
          price += plan.custom.services.publish_additional_price * servicesSelected.value * ( socialMediaServicesActive - 1 );
        }
      }else{
        if(servicesSelected.value > plan.custom.services.included) {
          price += plan.custom.services.publish_active_price;
          if(socialMediaServicesActive > 1){
            price += plan.custom.services.publish_additional_price * ( servicesSelected.value - plan.custom.services.included ) * ( socialMediaServicesActive - 1 );
          }
        }
      }
    }

    // Products
    if(plan.custom.products.active === true && productsSelected.value > 0 && socialMediaProductsActive > 0) {
      // Creation
      if(plan.custom.products.included === 0){
        price += plan.custom.products.active_price;
        if(productsSelected.value > 1){
          price += plan.custom.products.additional_price * ( productsSelected.value - 1 );
        }
      }else{
        if(productsSelected.value > plan.custom.products.included) {
          price += plan.custom.products.active_price;
          if(productsSelected.value > plan.custom.products.included){
            price += plan.custom.products.additional_price * ( productsSelected.value - plan.custom.products.included );
          }
        }
      }
      // Publish
      if(plan.custom.products.included === 0){
        price += plan.custom.products.publish_active_price;
        if(socialMediaProductsActive > 1){
          price += plan.custom.products.publish_additional_price * productsSelected.value * ( socialMediaProductsActive - 1 );
        }
      }else{
        if(productsSelected.value > plan.custom.products.included) {
          price += plan.custom.products.publish_active_price;
          if(socialMediaProductsActive > 1){
            price += plan.custom.products.publish_additional_price * ( productsSelected.value - plan.custom.products.included ) * ( socialMediaProductsActive - 1 );
          }
        }
      }
    }

    // Languages
    if(plan.custom.languages.active === true && languagesSelected.value > 1) {
      if(plan.custom.languages.included === 0){
        price += plan.custom.languages.active_price + ( plan.custom.languages.additional_price * ( languagesSelected.value - 1 ) );
        if(languagesSelected.value > 1){
          // Services 
          if(socialMediaServicesActive > 1){
            price += plan.custom.languages.service_price * servicesSelected.value * socialMediaServicesActive * ( languagesSelected.value - 1 );
          }
          // Products 
          if(socialMediaProductsActive > 1){
            price += plan.custom.languages.product_price * productsSelected.value * socialMediaProductsActive * ( languagesSelected.value - 1 );
          }
        }
      }else{
        if(languagesSelected.value > plan.custom.languages.included) {
          price += plan.custom.languages.active_price + ( plan.custom.languages.additional_price * ( languagesSelected.value - plan.custom.languages.included ) );
          // Services 
          if(socialMediaServicesActive > 0){
            price += plan.custom.languages.service_price * servicesSelected.value * socialMediaServicesActive * ( languagesSelected.value - plan.custom.languages.included );
          }
          // Products 
          if(socialMediaProductsActive > 0){
            price += plan.custom.languages.product_price * productsSelected.value * socialMediaProductsActive * ( languagesSelected.value - plan.custom.languages.included );
          }
        }
      }
    }
    
    // Shootings
    if(plan.custom.shootings.active === true) {
      if(shootingsSelected.value > plan.custom.shootings.included) {
        price += plan.custom.shootings.active_price;
        if(shootingsSelected.value > 1){
          price += plan.custom.shootings.additional_price * ( shootingsSelected.value - 1 );
        }
      }
    }

    setPrice(price);

    // Delivered
    if(plan.custom.delivered.active === true) {
      var delivered = plan.custom.delivered.social_media * socialMediasSelected.length;

      // Photos
      if(photosSelected.value > 0 && socialMediaPhotosActive > 0){
        delivered += plan.custom.delivered.photo * photosSelected.value * socialMediaPhotosActive;
      }

      // Services
      if(servicesSelected.value > 0 && socialMediaServicesActive > 0){
        delivered += plan.custom.delivered.service * servicesSelected.value * socialMediaServicesActive;
      }

      // Produits
      if(productsSelected.value > 0 && socialMediaProductsActive > 0){
        delivered += plan.custom.delivered.product * productsSelected.value * socialMediaProductsActive;
      }

      // Languages
      if(languagesSelected.value > plan.custom.languages.included) {
        delivered += plan.custom.delivered.language.social_media * socialMediasSelected.length;
        if(photosSelected.value > 0 && socialMediaPhotosActive > 0){
          delivered += plan.custom.delivered.language.photo * photosSelected.value * socialMediaPhotosActive * languagesSelected.value;
        }
        if(servicesSelected.value > 0 && socialMediaServicesActive > 0){
          delivered += plan.custom.delivered.language.service * servicesSelected.value * socialMediaServicesActive * languagesSelected.value;
        } 
        if(productsSelected.value > 0 && socialMediaProductsActive > 0){
          delivered += plan.custom.delivered.language.product * productsSelected.value * socialMediaProductsActive * languagesSelected.value;
        }
      }

      // Shooting
      if(shootingsSelected.value > 0 && socialMediaProductsActive > 0){
        delivered += plan.custom.delivered.shooting * shootingsSelected.value;
      }

      setDelivered(Math.ceil(delivered));
    }

    // Modifications
    if(plan.custom.modifications.active === true) {
      var modifications = Math.floor(price / plan.custom.modifications.every_price);
      if (modifications > plan.custom.modifications.number_maximum ) {
        modifications = plan.custom.modifications.number_maximum;
      } else if(modifications < plan.custom.modifications.number_minimum ) {
        modifications = plan.custom.modifications.number_minimum;
      }

      setModifications(Math.ceil(modifications));
    }

    // Message
    var message = t('simulator.contact.message.service');
    message += '<br>';
    message += t('simulator.contact.message.iNeed', { social_medias: socialMedias});
    if(plan.custom.photos.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.photos', { count: photosSelected.value });
    }
    if(plan.custom.services.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.services', { count: servicesSelected.value });
    }
    if(plan.custom.products.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.products', { count: productsSelected.value });
    }
    if(plan.custom.languages.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.languages', { count: languagesSelected.value });
    }
    if(plan.custom.shootings.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.shootings', { count: shootingsSelected.value });
    }
    message += '<br>';
    message +=  t('simulator.contact.message.price', { service_price: price });
    if(plan.custom.delivered.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.delivered', { count: Math.ceil(delivered) });
    }
    if(plan.custom.modifications.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.modifications', { count: Math.ceil(modifications) });
    }
    setValue("message",message);

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    updateSimulator();
  });

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border:  "none",
      color: '#377dff',
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#377dff',
    }),
    option: (provided, state) => ({
       ...provided,
       fontSize: "1rem"
    }),
  }

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#377dff",
      danger: '#ed4c78',
      dangerLight: '#f5ca99',
      neutral0: '#ffffff',
      neutral5: '#f7faff',
      neutral10: '#f8fafd',
      neutral20: "rgba(33, 50, 91, 0.1)",
      neutral30: '#bdc5d1',
      neutral40: '#97a4af',
      neutral50: '#8c98a4',
      neutral60: '#677788',
      neutral70: '#71869d',
      neutral80: '#1e2022',
      neutral90: '#21325b',
    },
    // Other options you can use
    border: 0,
    borderRadius: 4,
    boxShadow: 'none',
  });

  // Telephone
  const handleChangeTelephone = (status, phoneNumber, country) => {
    setValue("telephone",phoneNumber)
  };

  const handleBlurTelephone = (status, phoneNumber, country) => {
    setValue("telephone",phoneNumber)
  };

  // Submit
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    await fetch(process.env.GATSBY_API_URL + 'messages', {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.GATSBY_API_KEY,
        "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
        "form-uuid": process.env.GATSBY_FORM_SIMULATOR_UUID,
        "language-code": language,
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(result => {
      if(result.error === false){
        setSubmitted(true);
        reset();
      }else if(result.error === true){
        setError(
          "submit",
          "submitError",
        );
      }
    })
    .catch(error => {
      setError(
        "submit",
        "submitError",
        `${t('simulator.contact.form.error')} ${error.message}`
      );  
    });
  };

  const showSubmitError = msg => <p className="text-danger">{t('simulator.contact.form.error')}</p>

  const showSubmitted = (
    <div className="m-4 text-center">
      <h3>{t('simulator.contact.form.sent.title')}</h3>
      <StaticImage className="img-fluid w-50" quality="30" src="../../../images/illustrations/la-small-agency-message.svg" alt="Image Description" />
      <p>{t('simulator.contact.form.sent.subtitle')}</p>
    </div>
  );

  const showForm = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} method="post">  
        <div className="m-4">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.firstName.placeholder')}
                  maxLength="100"
                  {...register("first_name", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.first_name && <div className="text-danger">{t('simulator.contact.form.firstName.required')}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.lastName.placeholder')}
                  maxLength="100"
                  {...register("last_name", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.last_name && <div className="text-danger">{t('simulator.contact.form.lastName.required')}</div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.email.placeholder')}
                  maxLength="255"
                  {...register("email", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.email && <div className="text-danger">{t('simulator.contact.form.email.required')}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <Controller
                  name="telephone"
                  id="telephone"
                  maxLength="20"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <IntlTelInput
                      inputClassName="form-control form-control-sm"
                      nationalMode={false}
                      autoPlaceholder={true}
                      autoHideDialCode={false}
                      format
                      formatOnInit={true}
                      preferredCountries={["fr", "gb", "ru"]}
                      onPhoneNumberChange={handleChangeTelephone}
                      onPhoneNumberBlur={handleBlurTelephone}
                      placeholder={t('simulator.contact.form.telephone.placeholder')}
                    />
                  )}
                />
                {errors.telephone && <div className="text-danger">{t('simulator.contact.form.telephone.required')}</div>}
              </div>
            </div>
            <textarea
              className="d-none"
              name="message"
              id="message"
              maxLength="1000"
              {...register("message", { required: false })}
              disabled={isSubmitting}
            >
            </textarea>
          </div>
        </div>
        <div className="text-center">
          {errors && errors.submit && showSubmitError(errors.submit.message)}
        </div>
        <button type="submit" className="card-footer card-link bg-light btn btn-ghost-primary text-center w-100" disabled={isSubmitting}>{t('simulator.contact.form.send')}<span className="bi-send small ms-1"></span></button>
      </form>
    </>
  );

  return (
    <div>
      {service === 'social_media_optimization' ?    
        <div className="row gx-0 align-items-md-start" id="plans">
          <div className="col-md-8 mb-3 mb-md-0">
            <div className="card card-lg zi-2" data-aos="fade-up">
              <div className="card-body">
                <div className="d-flex flex-wrap lead text-dark">
                  <div className="">
                    <span>{t('simulator.sentence.iNeed')}</span>
                    {plan.custom.social_medias.active === true &&
                      <span>
                        <Controller
                          name="social_medias"
                          id="social-medias"
                          rules={{ required: false }}
                          disabled={isSubmitting}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="d-inline-block"
                              placeholder=".........."
                              noOptionsMessage={() => t('simulator.sentence.socialMediasNoOptionsMessage')}
                              isMulti
                              isClearable={false}
                              cacheOptions
                              defaultOptions
                              value={socialMediasSelected}
                              options={optionsSocialMedias}
                              onChange={handleChangeSocialMedias}
                              styles={selectCustomStyles}
                              theme={selectTheme}
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            />
                          )}
                        />
                      </span>
                    }
                    <span>{t('simulator.sentence.andAdd')}</span>
                    {socialMediasSelected.length > 0 &&
                      <>
                        {socialMediaPhotos > 0 && plan.custom.photos.active === true &&
                          <span>
                            {plan.custom.photos.number_maximum === 1 &&
                              <span> {t('simulator.sentence.photo')}</span>
                            }
                            {plan.custom.photos.number_maximum > 1 &&
                              <span>
                                <Controller
                                  name="photos"
                                  id="photos"
                                  rules={{ required: false }}
                                  disabled={isSubmitting}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      className="d-inline-block"
                                      cacheOptions
                                      defaultOptions
                                      value={photosSelected}
                                      options={optionsPhotos}
                                      onChange={handleChangePhotos}
                                      styles={selectCustomStyles}
                                      theme={selectTheme}
                                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    />
                                  )}
                                />
                                <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.photosPopoverContent')}>
                                  <span>{t('simulator.sentence.photos', { count: photosSelected.value })}</span>
                                </span>
                              </span>
                            }
                          </span>
                        }
                        {socialMediaServices > 0 && plan.custom.services.active === true &&
                          <span>
                            <Controller
                              name="services"
                              id="services"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={servicesSelected}
                                  options={optionsServices}
                                  onChange={handleChangeServices}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.servicesPopoverContent')}>
                              <span>{t('simulator.sentence.services', { count: servicesSelected.value })}</span>
                            </span>
                          </span>
                        }
                        {socialMediaProducts > 0 && plan.custom.products.active === true &&
                          <span>
                            <Controller
                              name="products"
                              id="products"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={productsSelected}
                                  options={optionsProducts}
                                  onChange={handleChangeProducts}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.productsPopoverContent')}>
                              <span>{t('simulator.sentence.products', { count: productsSelected.value })}</span>
                            </span>
                          </span>
                        }
                        {plan.custom.languages.active === true &&
                          <span>
                            <Controller
                              name="languages"
                              id="languages"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={languagesSelected}
                                  options={optionsLanguages}
                                  onChange={handleChangeLanguages}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span>{t('simulator.sentence.languages', { count: languagesSelected.value })}</span>
                          </span>
                        }
                        {plan.custom.shootings.active === true &&
                          <span>
                            <Controller
                              name="shootings"
                              id="shootings"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={shootingsSelected}
                                  options={optionsShootings}
                                  onChange={handleChangeShootings}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.shootingsPopoverContent')}>
                              <span>{t('simulator.sentence.shootings', { count: shootingsSelected.value })}</span>
                            </span>
                          </span>
                        }
                      </>
                    } 
                  </div>
                </div>
              </div>
              <hr className="mt-0 mb-0"/>
              <div>{submitted ? showSubmitted : showForm}</div>
            </div>
          </div>
          {socialMediasSelected.length > 0 &&
            <div className="col-md-4">
              <div className="card card-lg bg-dark ms-md-n2" data-aos="fade-up" data-aos-delay="200">
                <div className="card-body text-center">
                  <div className="card-text text-white-70">{t('simulator.from')}</div>
                  <div className="mb-3" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.allTaxesExcluded')}>
                    {price &&
                      <div className="text-white"><span className="display-1">{t('simulator.price', { service_price: price })}</span></div>
                    }
                  </div>
                  {delivered && plan.custom.delivered.active === true &&
                    <div className="card-text text-white-70 small">{t('simulator.deliveredWithinWorkingDays', { count: delivered, working_days_delivered: delivered })}</div>
                  }
                  {modifications && plan.custom.modifications.active === true &&
                    <div className="card-text text-white-70 small" data-bs-toggle="tooltip" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-original-title={t('simulator.modificationsAfterDeliveryTooltip')}>{t('simulator.modificationsAfterDelivery', { count: modifications, modifications_after_delivery: modifications })}</div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      : null}
    </div>
  );

};

export default SocialMediaOptimizationSimulatorSection;
